<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/study/study">学习中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/study/examSignup?configId=' + this.$route.query.configId">我的考试</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">确认信息</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="write-panel">
      <!-- 加载动态 -->
      <a-spin class="sping" v-show="loading || uploading" size="large" />
      <div class="side-item">
        <h4 class="title">1.证书信息确认</h4>
        <div class="personnel-detail">
          <div class="li-item">
            <span class="li-title">姓名：</span>
            <a-input class="li-input" :disabled="true" v-model="name" />
          </div>
          <div class="li-item">
            <span class="li-title">身份证号：</span>
            <a-input class="li-input" :disabled="true" v-model="idcard"  />
          </div>
          <div class="li-item">
            <span class="li-title">性别：</span>
            <a-radio-group v-model="sex" :disabled="true" name="radioGroup">
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </div>
          <div class="li-item">
            <span class="li-title">联系方式：</span>
              <a-input class="li-input" :disabled="true" v-model="mobile" />
          </div>
          <div class="li-item">
            <span class="li-title">学历：</span>
            <a-select
                class="li-input"
                placeholder="请选择学历"
                v-model="educationIndex"
              >
                <a-select-option v-for="(item,index)  in educationColumns" @click="oneducationConfirm(item)" :key="index" :value="index">{{item.name}}</a-select-option>
              </a-select>
          </div>
          <div class="li-item">
            <span class="li-title">出生日期：</span>
            <a-date-picker class="li-input" placeholder="请选择出生日期" valueFormat="YYYY-MM-DD" v-model="birthday" />
          </div>
          <div class="li-item">
            <span class="li-title">职称/职务：</span>
            <a-select
                class="li-input"
                v-model="workIndex"
                placeholder="请选择职称/职务"
              >
                <a-select-option v-for="(item,index) in workColumns" :key="index" @click="onworkConfirm(item)" :value="index">{{item.name}}</a-select-option>
              </a-select>
          </div>
          
          <!-- <div class="li-item">
            <span class="li-title">工作单位：</span>
            <a-select
                class="li-input"
                mode="combobox"
                placeholder="请输入工作单位"
                v-model="workUnit"
                @change="getWorkList"
              >
                <a-select-option v-for="(item,index) in workArr" :key="index" @click="onUnitConfirm(item)" :value="item.name">{{item.name}}</a-select-option>
              </a-select>
          </div> -->
        </div>
      </div>
      <div class="side-item">
        <h4 class="title">2.{{verifyType == 1 ? '证书照片确认' : '照片提交方式确认'}}</h4>
        <div class="way-inner">
          <p class="tips" v-if="verifyType == 1"><img src="@/assets/image/study/tips2.png" alt="">提示：您收到的证书上的照片为以下照片，请确认，确认后将开始制作证书，不再支持修改。</p>
          <p class="tips" v-else><img src="@/assets/image/study/tips2.png" alt="">提示：提交证书照片为您提供了两种方式：1.邮寄纸质照片（无需缴纳费用）；2.使用已上传的电子照片（需支付代为冲印费20元）</p>
          <!-- 提交方式 -->
          <div class="submit-way">
            <div class="submit-item" :class="{'submit-item-active' : type==2}" @click="type = 2">
              <div class="head-title">
                {{verifyType == 1 ? '使用已上传的电子照片' : '方式一：使用已上传的电子照片'}}
                <img src="@/assets/image/study/20230518001.png" alt="">
              </div>
              <div class="Photo">
                <img class="certifiMobile" v-if="certifiMobile" :src="certifiMobile" alt="">
                <div class="upload" v-else>
                  <img src="@/assets/image/study/20230518002.png" alt="">
                </div>
              </div>
              <p class="all-btn-small"  @click="onclickImg()">更换上传</p>
            </div>
            <div class="submit-item" v-if="verifyType == 0" :class="{'submit-item-active' : type==1}" @click="type = 1">
              <div class="head-title">
                方式二：邮寄纸质照片
                <img src="@/assets/image/study/20230518001.png" alt="">
              </div>
              <div class="tips-box">
                <p>请按以下要求邮寄照片：</p>
                <p>1.一张1寸蓝底或白底纸质照片</p>
                <p>2.照片后面要用圆珠笔写您的姓名和手机号</p>
                <p>3.附一张白纸写您的姓名、手机号、身份证号、考试时间及场次。</p>
                <p>4.邮寄地址如下</p>
              </div>
              <div class="tips-box">
                <p>{{addressObj.proviceName}}{{addressObj.cityName}}{{addressObj.areaName}}</p>
                <p>{{addressObj.address}}</p>
                <p>{{addressObj.name}} {{addressObj.mobile}}</p>
              </div>
              <!-- 物流信息 -->
              <div class="input-num">
                <p>快递公司：</p>
                <a-input v-model="postCompany" placeholder="请选择物流公司" />
              </div>
              <div class="input-num">
                <p>物流单号：</p>
                <a-input v-model="postNum" placeholder="请上传物流单号" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="side-item step3">
        <div class="flex">
          <h4 class="title">3.收货地址</h4>
          <p @click="visible=true"><img src="@/assets/image/study/icon_address_add.png" class="img"> 新增收货地址</p>
        </div>
        <ul class="listData" v-if="addressData.length">
          <li class="li1" :class="{'li1-hover':item.id == userAddressObj.id}" v-for="item in addressData" @click="onSelectAddress(item)" :key="item.id">
            <!-- 背景上边框 -->
            <div class="border">
              <div class="horn left-top"></div>
              <div class="border-top-line" />
              <div class="horn right-top"></div>
            </div>
            <!-- 列表显示内容 -->
            <div class="message">
              <div class="div2">
                <div class="address">
                  <img
                    src="@/assets/image/study/icon_address_address.png"
                    class="img"
                  />
                  <p class="name">{{item.consignee}}</p>
                  <p class="mobile">{{ item.phone ? item.phone.substr(0, 3):'' }}****{{ item.phone ? item.phone.substr(7, 11) : '' }}</p>
                  <p class="flag" v-if="item.isDefault == 1">默认</p>
                </div>
                <p class="addressFont">
                  {{ item.proviceName }}-{{ item.cityName }}-{{ item.areaName }} {{ item.address }}
                </p>
              </div>
            </div>
            <!-- 列表下边框 -->
            <div class="border">
              <div class="horn left-bottom"></div>
              <div class="border-bottom-line" />
              <div class="horn right-bottom"></div>
            </div>
          </li>
        </ul>
        <div class="none" v-else>
          <img src="@/assets/image/personalCenter/20220729-100305.png" alt="" />
          <span>暂无地址，<a @click="visible = true">新建收货地址</a></span>
        </div>
      </div>
       <!-- 提交按钮 -->
      <div class="submit-btn">
        <span class="cancel-btn" @click="$router.go(-1)">取消</span>
        <span class="all-btn-small" v-if="type == 2 && !isPay" @click="onPay">支付</span>
        <span class="all-btn-small" v-else @click="askOK()">确认</span>
      </div>
    </div>
    
    <a-modal
      title="新建收货地址"
      :visible="visible"
      :closable="false"
      width="560px"
      align="center"
      @cancel="visible = false"
      @ok="onAddressAdd"
    >
      <div class="modal-content">
        <div class="item">
          <span>收货人：</span>
          <a-input placeholder="请输入姓名" v-model="form.consignee" />
        </div>
        <div class="item">
          <span>手机号：</span>
          <a-input placeholder="请输入手机号" v-model="form.phone" />
        </div>
        <div class="item">
          <span>选择省份：</span>
            <Province 
            @regionCity='getPgAddressCode' 
            pgPlaceholder='选择地区'
            :class="{'regionWidth':addressValue==0}"
            :addressValue="addressValue"
            v-model="addressValue" ></Province>
        </div>
        <div class="item">
          <span>详细地址：</span>
          <a-input placeholder="请输入详细地址" v-model="form.address" />
        </div>
      </div>
    </a-modal>

    <!-- 冲印费确认支付 -->
    <TipsModal 
      :isCancelBtnShow="false"
      ConfirmBtnText="我已支付"
      :isPublicTipsShow="showPopup" 
      tipsText="照片冲印费支付成功后刷新页面或点击“我已支付”"
      @setEvent="getIsPay" />
      
    <!-- 上传图片 -->
    <input
      type="file"
      ref="file"
      @change="uploadImg"
      accept="image/jpg, image/png, image/jpeg"
      style="display: none"
    />
  </div>
</template>

<script>
import Province from '@/components/provinceCity'
import { uploadObject } from "@/unit/cosUpload.js";
export default {
  // 可用组件的哈希表
  components: {Province},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      addressData: [], // 地址
      configId:0, // 考试id
      examineeId:0, 
      signupUserId:0,
      signupId: 0,
      addressId:0,
      visible:false, // 新建收货地址弹窗
      uploading:false,
      addressValue: [], // 地区
      loading: false,


      /* 报名基础信息 */
      certifiMobile: '', //证件照片
      name: '', //姓名
      sex: '', //性别
      birthday: '', //出生日期
      mobile: '', //联系方式
      idcard: '', //身份证号
      education: "", //学历
      post: '', //职称/职务
      workUnit: '', //工作单位
      
      verifyType: 0, // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式

      educationColumns: [], // 学历
      educationIndex: undefined, // 学历索引
      workColumns: [], // 职位职称
      workIndex: undefined,
      workArr:[], // 工作单位

      addressObj:{}, // 证书照片邮寄地址
      type: 2, //照片提交方式 1纸质 2电子照
      isPay:false, // 是否支付

      // 地址参数
      form: {
        address: null, //详细地址
        provice: undefined, //省
        city: undefined, //市
        area: undefined, //区
        consignee: "", //收货人
        isDefault: 0, //是否默认-0否 1是
        phone: "", //	手机号
      },

      userAddressObj: {}, // 证书收货地址
      postCompany: '', //物流公司
      postNum: '', //物流单号


      // 支付功能
      orderId: 0,
      payLoad:false, // 支付防抖
      PayMessage: '',
      showPopup:false,

      isLoading: false
    }
  },
  // 事件处理器
  methods: {
    onPay(){
      // 出生日期
      if (!this.birthday) {
        this.$message.error('请选择出生日期');
        return
      }
      // 工作单位
      // if (!this.workUnit) {
      //   this.$message.error('请选择工作单位');
      //   return
      // }
      if(this.orderId){
        let url = window.location.origin + '/pay/payMethod?orderId=' + this.$AES.encode_data(this.orderId + '') + '&productType=' + this.$AES.encode_data( 4 + '' )
        window.open(url,'_blank');
      }
      // 电子照片商品信息
      let productList = [
        {
          city:'',
          count: 1,
          noGift: 0,
          productId: this.examineeId,
          province: '',
          type: 5
        }
      ]
      if(this.payLoad || this.orderId){return}
      this.payLoad = true
      this.PayMessage = this.$message.loading('正在调起支付功能..', 0);
      // 获取订单信息
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/confirm/order',
        method:'POST',
        params: productList
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.onCreateOrder(res.data)
        } else {
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },
    // 生成订单
    onCreateOrder(e){
      let obj = e.productList[0]
      let orderForm = {
        couponIds: [],
        couponName: '',
        couponPrice: 0,
        freightPrice: 0,
        invoiceId: null,
        isInvoice: 0,
        orderPrice: obj.totalPrice,
        orderType: 2,
        payPrice: obj.totalPrice,
        productList: [
          {
            courseDeferralDaysNotes: '',
            discountPrice: '',
            examNumberNotes: '',
            kfxhMumber: 0,
            noPay: 0,
            openTimeId: null,
            otherNotes: '代为冲印费',
            payPrice: obj.totalPrice,
            picture: this.certifiMobile,
            productCount: 1,
            productId: obj.productId,
            productPrice: obj.totalPrice,
            productType: 5,
            type: 1,
          }
        ],
        receiveId: '',
        source: '',
        sourceId: '',
      }
      this.$ajax({
        url:'/hxclass-pc/order/',
        method:'post',
        params:orderForm
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderId = res.data.orderId
          this.onRelevanceId(res.data.orderId)
        }else{
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },
    // 关联id
    onRelevanceId(orderId) {
      this.$ajax({
        url:"/hxclass-pc/exam/signUp/saveSignUpUserAndOrder",
        method:'put',
        params:{
          orderId: Number(orderId),
          signUpUserId: this.signupUserId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.StagInfo()
        } else {
          this.$message.error('支付调起失败');
          this.payLoad = false
        }
      })
    },

    StagInfo(){
      let params = {}
      params.auditStatus = 2 //出生日期
      params.birthday = this.birthday //职称/职务
      params.education = this.education //学历
      params.idcard = this.idcard //身份证号
      params.mobile = this.mobile //联系方式
      params.name = this.name //姓名
      params.post = this.post //职称/职务
      params.sex = parseInt(this.sex) //性别
      params.signupUserId = parseInt(this.signupUserId) //考生id
      params.userId = this.$store.state.userInfo.userId //用户id
      // params.workUnit = this.workUnit //工作单位
      params.certifiMobile = this.certifiMobile //证件照

      if(this.loading){return}
      this.loading = true

      // 第一步：编辑用户信息
      this.$ajax({
        url: '/hxclass-pc/exam/signup/edit',
        method: 'put',
        params: params
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let url = window.location.origin + '/pay/payMethod?orderId=' + this.$AES.encode_data(this.orderId + '') + '&productType=' + this.$AES.encode_data(4 + '')
          window.open(url,'_blank');
          this.showPopup = true
          this.$message.success('支付调起成功');
        } else {
        }
        this.loading = false
      });
    },
    
    //点击图片唤起本地文件夹
    onclickImg() {
      if(this.uploading){
        this.$message.warning('图片正在上传中，请稍后重试');
        return
      }
      this.$refs.file.value=null
      this.$refs.file.click()
    },

    uploadImg(e){
      if (!this.$regular.imgReg.reg.test(e.target.files[0].name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }


      _this.uploading = true

      // 腾讯云对象存储上传文件
      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）

      uploadObject(file, bucket, _this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.certifiMobile = url
        } else {
          _this.$message.error("上传失败");
        }
        _this.uploading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 获取籍贯
    getPgAddressCode(data, name){
      this.form.provice = data[0]
      this.form.area = data[1]
      this.form.city = data.length > 2 ? data[2] : ''
      this.addressValue = data
    },
    // 学历列表
    getEducation() {
      this.$ajax({
        url: "/hxclass-pc/nation/degree/list",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.educationColumns = res.data
        }
        this.getWork()
      });
    },
    // 职务列表
    getWork() {
       this.$ajax({
        url: "/hxclass-pc/job/list/all",
        method: "get"
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.workColumns = res.data
        }
        this.getSignupDetail()
      });
    },
    // 报名基础信息详情
    getSignupDetail() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/exam/user-signup-info",
        method: "get",
        params: {
          signupUserId: this.signupUserId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 回显报名信息
          this.echoData(res.data)
        }
        this.loading = false
      });
    },

    echoData(data){
      this.signupId = data.signupId //考试id
      this.addressId = data.address //退货地址id
      this.verifyType = data.verifyType || 0 // 照片确认方式 0:邮寄和上传两种方式 1:仅可上传修改一种方式
      if(this.verifyType == 1){
        this.isPay = true
      }
      this.certifiMobile = data.certifiMobile //电子照片
      this.name = data.name //姓名
      // 性别  全部改为int类型 
      if (data.sex == '2' || data.sex == 2) {
        this.sex = 0
      } else {
        this.sex = parseInt(data.sex) //性别
      }
      this.birthday = data.birthday //出生日期
      this.mobile = data.mobile //联系方式
      this.idcard = data.idcard //身份证号
      this.education = data.education //学历
      this.educationIndex = this.educationColumns.findIndex((item)=>{return item.name==data.education}) //学历索引
      if(this.educationIndex < 0 && !data.education){
        this.educationIndex = undefined
      } else if (this.educationIndex < 0) {
        this.educationIndex = data.education
      }
      this.post = data.post //职称/职务
      this.workIndex = this.workColumns.findIndex((item)=>{return item.name==data.post}) //学历索引
      if(this.workIndex < 0  && !data.post){
        this.workIndex = undefined
      } else if (this.workIndex < 0) {
        this.workIndex = data.post
      }

      // this.workUnit = data.workUnit //工作单位


      // this.getWorkList(data.workUnit)
      this.getAddress()
    },

    // 选择学历
    oneducationConfirm(e) {
      this.education = e.name;
    },

    // 选择职位职称
    onworkConfirm(e) {
      this.post = e.name;
    },

    // 选择工作单位
    onUnitConfirm(e) {
      this.workUnit = e.name;
    },

    // 工作单位列表
    getWorkList(keyword) {
      if(keyword){
        clearTimeout(this.tiemID);
        this.tiemID = setTimeout(() => {
          // 工作单位列表
          this.$ajax({
            url: "/hxclass-pc/work/sort/list",
            params: {
              keyword: keyword
            }
          }).then((res) => {
            if (res.code == 200 && res.success) {
              let workArr = []
              res.data.map((item, index) => {
                workArr.push(...item.sortDetailVOList)
              })
              this.$set(this, 'workArr', workArr)
            }
          });
        }, 500);
      } else {
        this.$set(this, 'workArr', [])
      }
    },

    onSelectAddress(e){
      this.userAddressObj = e
    },

    // 获取地址信息列表
    getAddressList() {
      this.$ajax({
        url: "/hxclass-pc/ship/address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 20,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressData = res.data.records;
          if(this.addressData.length){
            this.addressData.forEach(element => {
              if(element.isDefault){
                this.userAddressObj = element
              }
            });
            if(!this.userAddressObj.id){
              this.userAddressObj = this.addressData[0]
            }
          }
        }
      });
    },

    // 是否已支付电子照片费用
    getIsPay() {
      this.showPopup = false
      this.$ajax({
        url: "/hxclass-pc/exam/examinee-pay",
        method: "get",
        params: {
          examineeId: this.examineeId,
        },
      }).then((res) => {
        this.isPay = res
      });
    },

    // 收货地址
    getAddress() {
      this.$ajax({
        url: '/hxclass-pc/admin-address/detail',
        method: 'get',
        params: {
          addressId: this.addressId
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressObj = res.data
        }
      });
    },

    onAddressAdd(){
      if(!this.form.consignee){
        this.$message.error('请输入收货人名称');
        return
      }
      if (!this.$regular.name.reg.test(this.form.consignee)) {
        this.$message.error(this.$regular.name.msg);
        return
      }
      if(!this.form.phone){
        this.$message.error('请输入收货人手机号');
        return
      }
      if (!this.$regular.phone.reg.test(this.form.phone)) {
        this.$message.error(this.$regular.phone.msg);
        return
      }
      if(!this.form.provice){
        this.$message.error('请输入收货人地区');
        return
      }
      if(!this.form.address){
        this.$message.error('请输入收货人详细地址');
        return
      }

      if(this.isLoading){return}
      this.isLoading = true;

      this.$ajax({
        url: '/hxclass-pc/ship/address/add',
        method: 'post',
        params: this.form
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getAddressList()
          this.$message.success('添加成功');
          this.visible = false

          this.form = {
            address: null, //详细地址
            provice: undefined, //省
            city: undefined, //市
            area: undefined, //区
            consignee: "", //收货人
            isDefault: 0, //是否默认-0否 1是
            phone: "", //	手机号
          }
          this.addressValue = []
        }
        this.isLoading = false;
      });
    },

    askOK(){
      // 出生日期
      if (!this.birthday) {
        this.$message.error('请选择出生日期');
        return
      }
      // 工作单位
      // if (!this.workUnit) {
      //   this.$message.error('请选择工作单位');
      //   return
      // }
      // 出生日期
      if (this.type == 1 && !this.postCompany) {
        this.$message.error('请输入物流公司');
        return
      }
      // 出生日期
      if (this.type == 1 && !this.postNum) {
        this.$message.error('请输入物流单号');
        return
      }
      // 出生日期
      if (!this.userAddressObj.id) {
        this.$message.error('请选择证书收货地址');
        return
      }

      let _this = this

      _this.$confirm({
        title: '确认信息后除非有姓名或身份证信息等错误，否则不再接受修改证书内容及邮寄地址',
        okText: '确认信息',
        onOk() {
          _this.onSaveInfo()
        },
        onCancel() {},
      });
    },

    // 证书信息确认
    onSaveInfo(){
      let params = {}
      params.auditStatus = 2 //出生日期
      params.birthday = this.birthday //职称/职务
      params.education = this.education //学历
      params.idcard = this.idcard //身份证号
      params.mobile = this.mobile //联系方式
      params.name = this.name //姓名
      params.post = this.post //职称/职务
      params.sex = parseInt(this.sex) //性别
      params.signupUserId = parseInt(this.signupUserId) //考生id
      params.userId = this.$store.state.userInfo.userId //用户id
      // params.workUnit = this.workUnit //工作单位
      params.certifiMobile = this.certifiMobile //证件照

      if(this.loading){return}
      this.loading = true
      // 第一步：编辑用户信息
      this.$ajax({
        url: '/hxclass-pc/exam/signup/edit',
        method: 'put',
        params: params
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.submitOK()
        } else {
          this.loading = false
        }
      });
    },
    // 确认信息
    submitOK() {
      // 第二步：提交确认信息
      let params = {
        examineeId: this.examineeId, //考生id
        buyerName: this.addressObj.name, //买方人名称
        buyerMobile: this.addressObj.mobile, //买方人电话
        buyerProvince: this.addressObj.province, //买方地址省
        buyerCity: this.addressObj.city, //买方地址市
        buyerArea: this.addressObj.area, //买方地址区
        buyerAddress: this.addressObj.address, //买方详细地址
        userName: this.userAddressObj.consignee, //考生收货人名称
        userMobile: this.userAddressObj.phone, //考生收货人电话
        userProvince: this.userAddressObj.provice, //考生收货地址省
        userCity: this.userAddressObj.city, //考生收货地址市
        userArea: this.userAddressObj.area, //考生收货地址区
        userAddress: this.userAddressObj.address, //考生收货详细地址

        isConfirm: 1, //是否确认 1.是 0.否
        mode: this.type, //照片提交方式 1.邮寄纸质照片 2.使用上传电子照片	
        logisticsCompany: this.type==1 ? this.postCompany : '', //物流公司
        logisticsNumber: this.type==1 ? this.postNum : '', //	物流单号
        isPay: this.type==2 ? 1 : 0, //是否支付 1.是 0.否
        picture: this.type==2 ? this.certifiMobile : '', //	电子照片
        price: this.type==2 ? 20 : 0, //	价格
      }

      this.loading = true
      this.$ajax({
        url:'/hxclass-pc/exam/examinee/submit',
        method:'PUT',
        params
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.success('确认信息完成');
          this.$router.go(-1)
        }
        this.loading = false
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.configId = this.$route.query.configId ? this.$AES.decode_data(this.$route.query.configId) : 0;
    this.examineeId = this.$route.query.examineeId ? this.$AES.decode_data(this.$route.query.examineeId) : 0;
    this.signupUserId = this.$route.query.signupUserId ? this.$AES.decode_data(this.$route.query.signupUserId) : 0;
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getAddressList()
    this.getEducation()
    this.getIsPay()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}
.write-panel{
  background: #ffffff;
  padding: 20px 30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
  }
  .personnel-detail{
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    .li-item{
      display: flex;
      align-items: center;
      margin-right: 24px;
      width: calc((100% - 100px) / 4);
      margin-bottom: 25px;
      .li-title{
        display: inline-block;
        min-width: 65px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
      }
      .li-input{
        flex: 1;
      }
    }
  }
  .way-inner{
    .tips{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      margin-top: 8px;
      img{
        width: 12px;
        margin-top: -3px;
        margin-right: 3px;
      }
    }
    .submit-way{
      margin-top: 23px;
      padding: 0 23px;
      display: flex;
      justify-content: space-between;
      .submit-item{
        width: calc((100% - 60px) / 2);
        height: 345px;
        padding: 24px;
        background: #F5FDFF;
        border: 1px solid #F5FDFF;
        text-align: center;
        box-sizing: border-box;
        .head-title{
          display: inline-block;
          border-radius: 5px;
          padding: 8px 20px;
          border: 1px solid #15B7DD;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 21px;
          img{
            margin-top: -1px;
            width: 15px;
          }
        }
        .Photo{
          width: 112px;
          height: 154px;
          border-radius: 5px;
          margin: 28px auto;
          .upload{
            width: 100%;
            height: 100%;
            background: #DEF9FF;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 38px;
            }
          }
          .certifiMobile{
            width: 100%;
            height: 100%;
          }
        }
        .all-btn-small{
          display: inline-block;
        }
        .tips-box{
          text-align: left;
          margin-top: 12px;
          >p{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
          }
        }
        .input-num{
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          margin-top: 12px;
          >p{
            min-width: 70px;
            text-align: left;
          }
          /deep/ .ant-input{
            background-color:#F5FDFF;
            border: 1px solid #B0F0FF;
            color: rgba(21,183,221,0.5);
          }
          .ant-input::-webkit-input-placeholder { 
            /* WebKit browsers，webkit内核浏览器 */
            color: rgba(21,183,221,0.5);
          } 
          .ant-input:-moz-placeholder { 
            /* Mozilla Firefox 4 to 18 */ 
            color: rgba(21,183,221,0.5);
          } 
          .ant-input::-moz-placeholder { 
            /* Mozilla Firefox 19+ */ 
            color: rgba(21,183,221,0.5);
          } 
          .ant-input:-ms-input-placeholder { 
            /* Internet Explorer 10+ */ 
            color: rgba(21,183,221,0.5);
          }
        }
      }
      .submit-item-active{
        border: 1px solid @theme;
        box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.15);
      }
    }
  }
  .step3{
    margin-top: 24px;
    .flex{
      display: flex;
      justify-content: space-between;
      p{
        cursor: pointer;
      }
      img{
        width: 16px;
        margin-bottom: 3px;
      }
    }
    .listData {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 10px 0px 0px 7px;

      .li1 {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        cursor: pointer;
        .img {
          width: 25px;
          height: 25px;
        }
        .border {
          display: flex;
          flex-direction: row;
          .border-top-line {
            width: 100%;
            border-top: 2px dashed #c4c4c4;
            margin-top: 1px;
          }
          .border-bottom-line {
            width: 100%;
            border-bottom: 2px dashed #c4c4c4;
          }
          .horn{
            width: 24px;
            height: 24px;
            display: inline-block;
          }
          .left-top{
            border-top: 3px solid #C4C4C4;
            border-left: 3px solid #C4C4C4;
          }
          .right-top{
            border-top: 3px solid #C4C4C4;
            border-right: 3px solid #C4C4C4;
          }
          .left-bottom{
            border-bottom: 3px solid #C4C4C4;
            border-left: 3px solid #C4C4C4;
          }
          .right-bottom{
            border-bottom: 3px solid #C4C4C4;
            border-right: 3px solid #C4C4C4;
          }
        }
        .message {
          display: flex;
          margin: 0px 24px;
          .address {
            display: flex;
            align-items: center;
            .img{
              width: 24px;
              height: 24px;
            }
            .name {
              font-size: 18px;
              font-weight: 500;
              color: #333333;
              margin-left: 10px;
            }
            .mobile {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              margin-left: 16px;
            }
            .flag {
              margin-left: 16px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              background: #15b7dd;
              padding: 2px 4px;
              box-shadow: inset 0px 0px 4px 0px #c9f4ff;
              border-radius: 2px;
            }
          }
          .addressFont {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
            margin: 16px 0px 0px 34px;
          }
        }
      }
      .li1-hover{
        .border{
          .border-top-line {
            border-top: 2px dashed @theme;
          }
          .border-bottom-line {
            border-bottom: 2px dashed @theme;
          }
          .left-top{
            border-top: 3px solid @theme;
            border-left: 3px solid @theme;
          }
          .right-top{
            border-top: 3px solid @theme;
            border-right: 3px solid @theme;
          }
          .left-bottom{
            border-bottom: 3px solid @theme;
            border-left: 3px solid @theme;
          }
          .right-bottom{
            border-bottom: 3px solid @theme;
            border-right: 3px solid @theme;
          }
        }
      }
    }
    .none {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      overflow: hidden;
      text-align: center;
      img {
        width: 138px;
        height: 138px;
        display: block;
        margin-top: 124px;
        margin: 0 auto;
      }
      span {
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        display: inline-block;
        margin-top: 24px;
        line-height: 23px;
      }
    }
  }

  .submit-btn{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
    .cancel-btn{
      text-align: center;
      border-radius: 23px;
      color: @theme;
      line-height: 34px;
      border: 1px solid @theme;
      width: 114px;
      height: 34px;
      margin-right: 24px;
    }
    span{
      width: 114px;
      font-size: 14px;
      line-height: 34px;
      height: 34px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
/deep/ .ant-modal-body{
  padding: 0 34px;
}
.modal-content{
  margin-top: 20px;
  .item{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    span{
      min-width: 80px;
      text-align: right;
      margin-right: 10px;
      white-space: nowrap;
    }
    /deep/ .cascader {
      flex: 1;
    }
    /deep/ .ant-input{
      height: 40px;
    }
    /deep/ .cascader{
      text-align: left;
    }
  }
}

.phone-content{
  
}
</style>
